<template>
   <div class="FAQHolder">
       <Slider />
        <v-flex xs10 sm10 offset-sm1 class="">
            <v-card tile flat v-if="loadingData">
                <v-card-text align="center" justify="center">
                     <v-progress-circular
                        :size="50"
                        :width="5"
                        color="purple"
                        indeterminate
                        class="mr-2"
                        ></v-progress-circular>
                        Loading FAQs ...

                </v-card-text>
            </v-card>

       
            <v-card color="" @click="showPosts(post.id)" flat tile height="" class="pa-0 mt-5 post-card" v-for="(post, index) in postData" :key="index">
                    <v-row  class="">
                        <v-col cols="12" lg="1" md="1" sm="6" xs="12"  >
                            <v-card flat tile>
                                <v-card-text justify="center" align="center">
                                    <v-icon size="56" class="">mdi-book-open-outline</v-icon>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="9" md="9" sm="12" xs="12" >
                            <v-card flat tile>
                                <v-card-title>{{ post.category }}</v-card-title>
                                <v-card-text>{{ post.description }}</v-card-text>
                            </v-card>
                        </v-col>
                
                    </v-row>
            </v-card>
       
        
             <v-card tile flat color="#f4f5f7" v-if="posts !== null">
                   <v-card-text  class="d-flex justify-space-between">
                        <v-btn-toggle v-if="currentPage > 1" >
                     <v-btn :color="`${zamuYellow}`">
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                      <v-btn depressed dark :color="`${zamuPurple2}`" @click="showPrevious()">
                        Show Previous
                      </v-btn>
                  </v-btn-toggle>
                  <v-btn-toggle v-if="currentPage <  pagination.lastPage" >
                      <v-btn depressed dark :color="`${zamuPurple2}`" @click="showMore()">
                        Show More
                      </v-btn>

                      <v-btn :color="`${zamuYellow}`">
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                  </v-btn-toggle>
                </v-card-text>
            </v-card>
        </v-flex>
    
   </div>
  
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';



export default {
    name: 'FAQs',
     components: {
      Slider: () => import('./slider'),
     },
    mixins: [coreMixin, colorMixin],

    data() {
        return {
            posts: null,
            currentPage: 1,
        };
    },
      computed: {
        postData () {
            return this.posts !== null ? this.posts.data : null;
        },
        pagination () {
            let data = null;
            if (this.posts !== null) {
                 data = {
                    total: this.posts.total,
                    perPage: this.posts.perPage,
                    page: this.posts.page,
                    lastPage: this.posts.lastPage,
                };
            }
            return data;

        },

    },
    watch: {
        async currentPage(currentPage) {
             this.posts = await this.retrieveActiveFAQCategories(currentPage);

        },
    },
     async mounted() {
        this.posts = await this.retrieveActiveFAQCategories(this.currentPage);
    },
    methods: {
        setPage(currentPage) {
            this.currentPage = currentPage;

        },
        showMore() {
            return this.currentPage = this.currentPage + 1;
        },
         showPrevious() {
            return this.currentPage = this.currentPage - 1;
        },
        
        showPosts(id) {
             return this.$router.push({ name: 'faq-category', params: { id: id } });
        }
    },

}
</script>

<style>
.v-expansion-panel {
    border: 1px solid #d9d9d9;
    margin-bottom: 6px;
    box-shadow: none !important;
}
.post-card {
    cursor: pointer;
}

</style>